import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { upcomingEvent, evenDetails } from "../../redux/upcoming-event/action";
import history from "../../history";
import Loader from "../spinner/spinner";
import moment from "moment";
import Tooltip from "react-simple-tooltip";
import { css } from "styled-components";
class UpcomingEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events_lits: "",
      viewAllevents: false,
      currentPage: 1,
      event_limit: 8,
      isMouseInside: false,
      activeItem: -1,
    };
  }
  componentDidUpdate(prevProps) {

    if (prevProps.event_list !== this.props.event_list) {
      if (this.props.event_list && this.props.event_list.event_list !== "") {
        //let firstEight = this.props.event_list.event_list.slice(0, 8);
        this.setState({
          events_lits: this.props.event_list.event_list,
        });
      }
    }
  }
  componentDidMount = async () => {
    let data = {
      limit: this.state.event_limit,
      page: this.state.currentPage,
      type: "upcoming",
    };
    await this.props.upcomingEvent(data);
    if (this.props.event_list && this.props.event_list.event_list !== "") {
      //let firstEight = this.props.event_list.event_list.slice(0, 8);
      
      this.setState({
        events_lits: this.props.event_list.event_list,
      });
    }
  };
  openEvent = async (event_id) => {
    if (event_id !== "") {
      await this.props.evenDetails(event_id);
    }
    if (this.props.event_details.event_details) {
      this.props.history.push(`/event-details/${event_id}`);
    }
  };
  viewAllevent() {
    this.props.history.push("/all-events");
  }
  mouseEnter = (index) => {
    this.setState({
      activeItem: index,
    });
  };
  mouseLeave = (index) => {
    this.setState({
      activeItem: -1,
    });
  };
  render() {
   
    return (
      <div className="coming_events" id="upcoming-events">
        <div className="container-coming-events container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 wow slideUp">
              <div className="section-heading user_title">
                <i
                  className="fa fa-calendar upcoming-events
                  "
                  aria-hidden="true"
                ></i>{" "}
                <h2> Upcoming Events</h2>{" "}
              </div>
            </div>
            {this.props.event_details.in_action ? (
              <Loader />
            ) : (
              <>
                {this.state.events_lits !== "" ? (
                  <>
                    {this.state.events_lits.map((event, index) => (
                      <div
                        className="col-lg-3 col-md-3 col-sm-6 col-xs-12 wow slideUp event-main"
                        onClick={() => this.openEvent(event.event_id)}
                      >
                        <div
                          className="event"
                          key={index}
                          onMouseEnter={() => this.mouseEnter(index)}
                          onMouseLeave={() => this.mouseLeave(index)}
                        >
                          <div className="artist_img">
                            <img
                              src={`https://api.ticketpenguin.co.uk/${event.event_image}`}
                              alt={`upcoming-event-img${index}`}
                            />
                          </div>
                          <div className="">
                            <div className="selling_top">
                              <h5>{event.event_name}</h5>
                              <h5
                                className={
                                  this.state.activeItem === index
                                    ? "location--active"
                                    : "location--hide"
                                }
                              >
                                <p>
                                  {" "}
                                  <i
                                    className="fa fa-map-marker location-marker"
                                    aria-hidden="true"
                                  ></i>
                                  {event.event_location}
                                </p>
                              </h5>
                              <p>
                                <i
                                  className="fa fa-calendar cal-icon-upevents"
                                  aria-hidden="true"
                                ></i> {" "}{" "}
                                {moment(event.event_time).format("Do MMMM")}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  "No records found"
                )}
              </>
            )}
          </div>
          <div className="viewallevents" onClick={() => this.viewAllevent()}>
            <span className="viewall">
              {" "}
              <i className="fa fa-eye eye-icon" aria-hidden="true"></i>
              View All
            </span>
          </div>
          {/* {this.state.events_lits && this.state.events_lits.length >= 8 ? (
            <div className="viewallevents" onClick={() => this.viewAllevent()}>
              <span className="viewall">
                {" "}
                <i className="fa fa-eye eye-icon" aria-hidden="true"></i>
                View All
              </span>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  event_list: state.event_list,
  event_details: state.event_details,
});
export default withRouter(
  connect(mapStateToProps, {
    upcomingEvent,
    evenDetails,
  })(UpcomingEvents)
);
