import React, { Component } from "react";

import SimpleSlider from "../Slider";
import SearchBar from "../components/main-search-bar";
import UpcomingEvents from "../components/upcoming-events/upcomingEvents";
import BestSelling from "../components/best-selling/bestSelling";
import PopularPerformer from "../components/popular-performer/popularPerformer";
class Home extends Component {
  render() {
    return (
      <>
      <title>Ticket Penguin Home</title>
        <div className="main-search-bar">
          <SearchBar />
        </div>

        <div className="upcoming-container">
          <UpcomingEvents />
        </div>
        <div className="best-selling-events">
          <BestSelling />
        </div>
        <div className="popular-performer">
          <PopularPerformer />
        </div>
      </>
    );
  }
}

export default Home;
