import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import { popularEventsList } from "../../redux/popular-events/action";
class BestSelling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popular_events: "",
      activeItem: -1,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.popular_event_list !== this.props.popular_event_list) {
      if (
        this.props.popular_event_list &&
        this.props.popular_event_list.popular_event_list !== ""
      ) {
        //let firstEight = this.props.event_list.event_list.slice(0, 8);
        this.setState({
          popular_events: this.props.popular_event_list.popular_event_list,
        });
      }
    }
  }
  componentDidMount() {
    this.props.popularEventsList();
  }
  openEvent = async (event_id) => {
    if (this.props.popular_event_list.popular_event_list) {
      this.props.history.push(`/event-details/${event_id}`);
    }
  };
  mouseEnter = (index) => {
    this.setState({
      activeItem: index,
    });
  };
  mouseLeave = (index) => {
    this.setState({
      activeItem: -1,
    });
  };
  render() {

    return (
      <div className="best_selling" id="best-selling-events">
        <div className="container-coming-events-popular-events container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 wow slideUp ">
              <div className="section-heading pop-performer">
                <h2>Popular Events</h2>{" "}
              </div>
            </div>

            {this.state.popular_events !== ""
              ? this.state.popular_events.map((event, index) => (
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 wow slideUp event-main">


                    <div
                      className="event best-selling"
                      onClick={() => this.openEvent(event.event_id)}
                      key={index}
                      onMouseEnter={() => this.mouseEnter(index)}
                      onMouseLeave={() => this.mouseLeave(index)}
                    >
                      <div className="artist_img">
                        <img
                          src={`https://api.ticketpenguin.co.uk/${event.event_image}`}
                          alt={`popular-event-img${index}`}
                        />
                      </div>

                      
                      <div className="selling_top">
                        <h5> {event.event_name} </h5>
                        <h5
                          className={
                            this.state.activeItem === index
                              ? "location--active"
                              : "location--hide"
                          }
                        >
                         <p> <i
                                  className="fa fa-map-marker location-marker"
                                  aria-hidden="true"
                                ></i>{event.event_location}</p> 
                        </h5>
                        <p>
                          <i
                            className="fa fa-calendar cal-icon-upevents"
                            aria-hidden="true"
                          ></i>{" "}
                          {moment(event.event_time).format("Do MMMM")}{" "}
                        </p>
                      </div>

                    </div>
                  </div>
                ))
              : "No records found"}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  popular_event_list: state.popular_event_list,
});
export default withRouter(
  connect(mapStateToProps, {
    popularEventsList,
  })(BestSelling)
);
