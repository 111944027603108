import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import { topPerformer } from "../../redux/performers/action";
import star1 from "../../assets/images/icons/star1.png";
import star3 from "../../assets/images/icons/star3.png";
import star2 from "../../assets/images/icons/star2.png";
import star6 from "../../assets/images/icons/star6.png";

class PopularPerformer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      performer_list: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.performer_list !== this.props.performer_list) {
      if (
        this.props.performer_list &&
        this.props.performer_list.performer_list !== ""
      ) {
        if (this.props.performer_list.performer_list.length <= 8) {
          this.setState({
            performer_list: this.props.performer_list.performer_list,
          });
        } else {
          this.setState({
            performer_list: this.props.performer_list.performer_list.slice(
              0,
              8
            ),
          });
        }
      }
    }
  }
  componentDidMount() {
    this.props.topPerformer();
  }
  openEvent = async (artist_id) => {
    if (artist_id !== "") {
      this.props.history.push(`/performers-details/${artist_id}`);
    }
  };
  render() {
    return (
      <div className="" id="star3">
        {/* <div class="shape_container">
          <div class="shape1">
            <img src={star3} />
          </div>
          <div class="shape2">
            <img src={star1} />
          </div>
          <div class="shape3">
            <img src={star3} />
          </div>
          <div class="shape4">
            <img src={star3} />
          </div>
          <div class="shape5">
            <img src={star3} />
          </div>
          <div class="shape6">
            <img src={star2} />
          </div>
          <div class="shape7">
            <img src={star6} />
          </div>
        </div> */}





        <div  className="coming-events-container container bg_img  flower_after">
          <div className="row align-items-center bg_star">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 wow slideUp ">
              <div className="section-heading top_user  ">
                <h2>Top Artists</h2>{" "}
              </div>
            </div>

            {this.state.performer_list !== ""
              ? this.state.performer_list.map((performer) => (
                  <div
                    className="col-lg-3 col-md-3 col-sm-6 col-xs-12 wow slideUp perfomer-box "
                    onClick={() => this.openEvent(performer.artist_id)}
                  >
                    <div className="">
                      <figure className="artist_img">
                        <img
                          src={`https://api.ticketpenguin.co.uk/${performer.artist_image}`}
                          alt={`top-performer-img-${performer.artist_id}`}
                        />
                      </figure>
                    </div>
                    <div className="artist-name">
                      <h3>{performer.artist_name}</h3>
                    </div>
                  </div>
                ))
              : "No records found"}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  performer_list: state.performer_list,
});
export default withRouter(
  connect(mapStateToProps, {
    topPerformer,
  })(PopularPerformer)
);
