import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
class Footer extends React.Component {
  openTermsAndCondtions = () => {
    this.props.history.push("/terms-and-conditions");
  };
  openPrivacyPolicies = () => {
    this.props.history.push("/privacy-policies");
  };
  openContactUs = () => {
    this.props.history.push("/contact-us");
  };
  openAboutUs = () => {
    this.props.history.push("/about-us");
  };
  openRefund = () => {
    this.props.history.push("/refund");
  };
  openFaq = () => {
    
  }
  moveToHome = () => {
    if(window.location.pathname ==='/'){
 
      let element = document.getElementById("main-search-box");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    }else{

      this.props.history.push("/");
    }
    
  };
  moveToUpcomingEvnets = () => {

    if(window.location.pathname ==='/'){
     
      let element = document.getElementById("upcoming-events");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    }else{

      this.props.history.push("/");
    }
  };
  moveToTopEvents = () => {

    if(window.location.pathname ==='/'){
     
      let element = document.getElementById("best-selling-events");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    }else{

      this.props.history.push("/");
    }
   
  };
  render() {
    return (
      <div id="footer">
        <div className="footer-top">
          <div className="footer-container">
            <div className="footer_fdx">
              <div className="footer_inr">
                <div className="footer-logo-box">
                  <img
                    src={require("../../src/assets/images/newlogo.png")}
                    className="Zaltol_footer"
                  />
                </div>

                <div className="adding-space"></div>
                <a className="trst_star">
                  <div
                    class="trustpilot-widget "
                    data-locale="en-GB"
                    data-template-id="5419b6a8b0d04a076446a9ad"
                    data-businessunit-id="638279349a073ce4bc31d083"
                    data-style-height="40px"
                    data-style-width="90%"
                    data-theme="light"
                    //data-style-alignment="center"
                  >
                    <a
                      href="https://uk.trustpilot.com/review/ticketpenguin.co.uk"
                      target="_blank"
                      rel="noopener"
                    >
                      Trustpilot
                    </a>
                  </div>
                </a>
                <div className="payments-cards">
                  <img
                    src={require("../../src/assets/images/cards.png")}
                    className="cards-accepted"
                  />
                </div>
              </div>

              <div className="footer_inr">
                <h4>Important Links </h4>
                <ul>
                  <li className="footer-links">
                    <a onClick={() => this.moveToHome()}>Home</a>
                  </li>
                  <li className="footer-links">
                    <a onClick={() => this.moveToUpcomingEvnets()}>
                      Upcoming Events{" "}
                    </a>
                  </li>
                  <li className="footer-links">
                    {" "}
                    <a onClick={() => this.moveToTopEvents()}>Top Events </a>
                  </li>
                </ul>
              </div>

              <div className="footer_inr">
                <h4>Company </h4>
                <ul>
                  <li>
                    <a onClick={() => this.openAboutUs()} target="blank">
                      About Us
                    </a>
                  </li>
                  <li>
                  <a onClick={() => this.openFaq()} target="blank">
                      {" "}
                      FAQs{" "}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.openContactUs()} target="blank">
                      Contact Us{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      className="terms-and-condtions"
                      onClick={() => this.openTermsAndCondtions()}
                      target="_blank"
                    >
                      Terms and Conditions{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      onClick={() => this.openPrivacyPolicies()}
                    >
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a target="_blank" onClick={() => this.openRefund()}>
                      Refund{" "}
                    </a>
                  </li>
                </ul>
              </div>

              <div className=" footer_inr">
                <h4>Need Help? </h4>
                <ul>
                  <li>
                    <a href="mailto:support@ticketpenguin.co.uk" target="blank">
                      {" "}
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      <span> support@ticketpenguin.co.uk</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ticket-phone-number">
                      {" "}
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <span> +44 1234 864646</span>
                    </a>
                  </li>
                </ul>
                <h4>Follow Us On </h4>
                <div className="social_links">
                  <ul className="fl_x">
                    {/* <li>
                      <a href="#">  
                        <i className="fa fa-facebook" aria-hidden="true"></i>{" "}
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://twitter.com/ticketpenguin"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ticketpenguin/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text_text">
          <p> Copyright © 2022-2030 Ticket Penguin. All Rights Reserved </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
export default withRouter(connect(mapStateToProps, {})(Footer));
