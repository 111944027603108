import React, { Component } from "react";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { userLogin } from "../../redux/login/action";
import { connect } from "react-redux";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      email: "",
      password: "",
      validateFieldsError: false,
      is_login:false
    };
  }
  goToSignUp = () => {
    this.props.history.push("/sign-up");
  };
  forgotPassword = () => {
    this.props.history.push("/forgot-password");
  };
  loginUser = () => {
    if (this.state.email && this.state.password !== "") {
      let data = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.userLogin(data);
    } else {
      this.setState({
        validateFieldsError: true,
      });
    }
  };
  handleInput = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.user_info !== this.props.user_info) {
    
      if (this.props.user_info.user_info.type == "success") {
        this.setState({
          is_login:true
        })
        localStorage.setItem(
          "user_info",
          JSON.stringify(this.props.user_info.user_info)
        );
        window.location.href = "/";
      }
    }
  }
  render() {
   if(this.state.is_login){
    if (localStorage.getItem("cart_details")) {
      window.location.href = "/cart-details"
    }else{
      return (
        window.location.href = "/"
      )
    }
 
   }
    return (
      <>
        <div className="login-main-container">
          <div className="login-internal">
            <div className="left-login">
              <div className="left-login-img">
                <img
                  src={require("../../assets/images/Group.png")}
                  className="left-login-img"
                />
              </div>
            </div>
            <div className="right-login">
              <div className="right-login-box">
                <div className="login-logo">
                  <img
                    src={require("../../assets/images/logo.png")}
                    className="login-logo-img"
                  />
                </div>
                <div className="login-user">Login</div>
                
                <div className="login-form-container">
                {this.props.user_info && this.props.user_info.error !=='' ?  <div className="input-field-error">
                        {this.props.user_info.error}
                      </div> :''}
                  <div className="login-form-internal">
                    <div className="email-text">Email Address</div>
                    <div className="email-box">
                      <input
                        className="email-input"
                        type="email"
                        placeholder="Enter Email Address"
                        onChange={this.handleInput}
                        name="email"
                      />
                    </div>
                    {this.state.validateFieldsError &&
                    this.state.email == "" ? (
                      <div className="input-field-error">
                        Please enter your email
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="email-text password">Password</div>
                    <div className="email-box">
                      <input
                        className="email-input"
                        type="password"
                        placeholder="Enter Password"
                        onChange={this.handleInput}
                        name="password"
                      />
                    </div>
                    {this.state.validateFieldsError &&
                    this.state.password == "" ? (
                      <div className="input-field-error">
                        Please enter your password
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="remember-me-box">
                      <div
                        onClick={this.forgotPassword}
                        className="forgot-password"
                      >
                        Forgot Password?
                      </div>
                    </div>
                    <div className="login-btn-box">
                      <div className="login-btn" onClick={this.loginUser}>
                        Login
                      </div>
                    </div>
                    <div className="border-box"></div>
                    <div className="sign-up-box">
                      <div className="not-registered">Not yet registered?</div>
                      <div className="sing-up" onClick={this.goToSignUp}>
                        Signup
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user_info: state.user_info,
});
export default withRouter(
  connect(mapStateToProps, {
    userLogin,
  })(Login)
);
