import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Login from "../pages/Login";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginForm: false,
      user_information: JSON.parse(localStorage.getItem("user_info")),
    };
  }

  loginForm = () => {
    this.setState({
      showLoginForm: true,
    });
  };

  loginClose = () => {
    this.setState({
      showLoginForm: false,
    });
  };
  componentDidMount() {
    this.setState({
      user_information: JSON.parse(localStorage.getItem("user_info")),
    });
  }
  componentDidUpdate() {}
  logOut = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  orderConfirmation = ()=>{
    this.props.history.push('/my-orders')
  }
  render() {
    let location = this.props.location.pathname.split("/");
    return (
      <div className="nav-flex">
        <div className="nav-logo">
          <NavLink className="nav-logo-link" to="/" exact>
            <img
              className="main-logo"
              src={require("../../src/assets/images/newlogo.png")}
            />
          </NavLink>
        </div>
        <div className="nav-menus">
          <div className="menu_list flex">
            <div className="nav-item">
              <NavLink
                to="/"
                className={
                  location["1"] == "" ? "nav-link active_nav" : "nav-link"
                }
                exact
              >
                Home
              </NavLink>
            </div>
            <div className="nav-item black">
              <NavLink
                className={
                  location["1"] == "contact-us"
                    ? "nav-link active_nav"
                    : "nav-link"
                }
                to="/contact-us"
                exact
              >
                Contact us
              </NavLink>
            </div>
            {this.state.user_information ? (
              <div className="nav-item header-dropdown ">
                {/* <div className="nav-link user-name" to="/login" exact onClick={()=>this.logOut()}>
                  MY ACCOUNT
                </div> */}
                <Dropdown className="header-dropdown">
                  <Dropdown.Toggle
                    variant="success"
                    className="header-dropdown-items"
                  >
                    My account
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item  onClick={() => this.orderConfirmation()} className="header-dropdown-items">
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => this.logOut()}
                      className="header-dropdown-items"
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="nav-item login-button">
                <NavLink
                  className={
                    location["1"] == "login"
                      ? "nav-link active_nav"
                      : "nav-link"
                  }
                  to="/login"
                  exact
                >
                  Login
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_info: state.user_info,
  user_added: state.user_added,
});
export default withRouter(connect(mapStateToProps, {})(Header));
