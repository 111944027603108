import React, { Component } from 'react';



class About extends React.Component {
  render() {
    return (
      <div className="container">
        <h1>About</h1>
      </div>
    );
  }
}

export default About;
