import React, { Component } from 'react';

class ErrorPage extends React.Component {
  render() {
    return (
      <div className="container">
        <h4>Error: page not found</h4>
      </div>
    );
  }
}

export default ErrorPage;
