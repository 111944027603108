import React, { Component } from "react";
import { withRouter } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { addUser } from "../../redux/signup/action";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countrycode: "+1",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      cofirmpassword: "",
      phonenumber: "",
      validateFieldsError: false,
      user_added_msg: "",
      privacy_policies: false,
      privacy_policies_error: false,
    };
  }
  goToLogin = () => {
    this.props.history.push("/login");
  };
  signUpUser = () => {
    if (this.state.privacy_policies) {
      let data = {
        email: this.state.email,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        password: this.state.password,
        phonenumber: this.state.phonenumber,
        country_code: this.state.countrycode,
      };
      this.props.addUser(data);
    } else {
      this.setState({
        privacy_policies_error:true,
        validateFieldsError:true
      })
    }
  };
  handleInput = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  onConfirm = () => {
    this.props.history.push("/login");
  };
  onCancel = () => {
    this.props.history.push("/login");
  };
  onConfirmCancel=()=>{
    this.props.history.push("/sign-up");
  }
  handlePrivacyPolicies = () => {
    if(this.state.privacy_policies === true){
      this.setState({
        privacy_policies:false,
        privacy_policies_error:true
      })
    }else{
      this.setState({
        privacy_policies:true,
        privacy_policies_error:false
      })
    }
 
  };
  componentDidUpdate(prevProps) {
    if (prevProps.user_added.user_added !== this.props.user_added.user_added) {
      if (this.props.user_added.user_added !== " ") {
 
        this.setState({
          user_added_msg: this.props.user_added.user_added,
        });
      }
    }
  }
  render() {
    return (
      <>
        <div className="login-main-container signup-main">
          {this.state.user_added_msg !== "" ? (
            <SweetAlert
            success
              title="User Added!"
              onConfirm={this.onConfirm}
              customButtons={
                <React.Fragment>
                  <button style={{background:'#0466ff',width:'20%',height:'40px',border:'none',color:'#fff',borderRadius:'11px'}} onClick={this.onConfirm}>OK</button>
                  
                </React.Fragment>
              }
            >
              {this.state.user_added_msg}
            </SweetAlert>


          ) : (
            ""
          )}
          {this.props.user_added.error !=='' ?
          <SweetAlert
          danger
            title="User Not Added"
            onConfirm={this.onConfirmCancel}
            customButtons={
              <React.Fragment>
                <button style={{background:'#0466ff',width:'20%',height:'40px',border:'none',color:'#fff',borderRadius:'11px'}}onClick={this.onConfirmCancel}>OK</button>
                
              </React.Fragment>
            }
          >
           Phone Number / Email Address already registered
          </SweetAlert> :''
        }
          <div className="login-internal">
            <div className="right-login">
              <div className="right-login-box">
                <div className="login-logo">
                  <img
                    src={require("../../assets/images/logo.png")}
                    className="login-logo-img"
                  />
                </div>
                <div className="login-user">Register an Account</div>
                <div className="login-form-container">
                  <div className="login-form-internal">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="email-text first-name">
                            First Name
                          </div>
                          <div className="email-box">
                            <input
                              name="firstname"
                              className="email-input"
                              type="text"
                              placeholder="Enter First Name"
                              value={this.state.firstname}
                              onChange={this.handleInput}
                            />
                          </div>
                          {this.state.validateFieldsError &&
                          this.state.firstname == "" ? (
                            <div className="input-field-error">
                              Please enter your firstname
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="email-text last-name">Last Name</div>
                          <div className="email-box">
                            <input
                              className="email-input"
                              type="text"
                              name="lastname"
                              placeholder="Enter First Name"
                              value={this.state.lastname}
                              onChange={this.handleInput}
                            />
                          </div>
                          {this.state.validateFieldsError &&
                          this.state.lastname == "" ? (
                            <div className="input-field-error">
                              Please enter your lastname
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="email-text">Email Address</div>
                          <div className="email-box">
                            <input
                              className="email-input"
                              type="email"
                              name="email"
                              placeholder="Enter Email Address"
                              value={this.state.email}
                              onChange={this.handleInput}
                            />
                          </div>
                          {this.state.validateFieldsError &&
                          this.state.email == "" ? (
                            <div className="input-field-error">
                              Please enter your email
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="email-text password">Password</div>
                          <div className="email-box">
                            <input
                              className="email-input"
                              type="password"
                              name="password"
                              placeholder="Enter Password"
                              value={this.state.password}
                              onChange={this.handleInput}
                            />
                          </div>
                          {this.state.validateFieldsError &&
                          this.state.password == "" ? (
                            <div className="input-field-error">
                              Please enter your password
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="email-text password">
                            Confirm Password
                          </div>
                          <div className="email-box">
                            <input
                              className="email-input"
                              type="password"
                              name="cofirmpassword"
                              placeholder="Enter Password"
                              value={this.state.cofirmpassword}
                              onChange={this.handleInput}
                            />
                          </div>
                          {this.state.validateFieldsError &&
                          this.state.cofirmpassword == "" ? (
                            <div className="input-field-error">
                              Please enter your password again
                            </div>
                          ) : (
                            ""
                          )}
                          {(this.state.password &&
                            this.state.cofirmpassword) !== "" &&
                          this.state.password !== this.state.cofirmpassword ? (
                            <div className="input-field-error">
                              Your password doesn't matched. Please provide the
                              same password.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="email-text first-name">
                            Phone Number
                          </div>
                          <div className="email-input-main">
                            <div className="email-box country-code">
                              <PhoneInput
                                className="email-input country-code"
                                country={"us"}
                                value={this.state.countrycode}
                                onChange={(countrycode) =>
                                  this.setState({ countrycode })
                                }
                              />
                            </div>

                            <input
                              className="email-input enter-phone-number"
                              type="text"
                              name="phonenumber"
                              placeholder="Enter Phone Number"
                              value={this.state.phonenumber}
                              onChange={this.handleInput}
                            />
                          </div>
                          {this.state.validateFieldsError &&
                          this.state.phonenumber == "" ? (
                            <div className="input-field-error">
                              Please enter your phone number
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="email-box privacy-policies-main ">
                      <div className="policies-box">
                        {" "}
                        <input
                          className="policies-check"
                          type="checkbox"
                          value="privacy-policies"
                          onChange={this.handlePrivacyPolicies}
                          defaultChecked={this.state.privacy_policies}
                        />
                      </div>
                      <div className="policies-text">
                        By signing in or creating an account, you acknowledge
                        and accept our privacy policy and terms and conditions.
                      </div>
                      {this.state.privacy_policies_error ? (
                        <div className="input-field-error">
                          Please accept privacy policies to proceed for signup.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="login-btn-box">
                      <div className="login-btn" onClick={this.signUpUser}>
                        Signup
                      </div>
                    </div>
                    <div className="border-box"></div>
                    <div className="sign-up-box">
                      <div className="not-registered">
                        Already have an account?
                      </div>
                      <div className="sing-up" onClick={this.goToLogin}>
                        Login
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user_added: state.user_added,
});
export default withRouter(
  connect(mapStateToProps, {
    addUser,
  })(Signup)
);
